<template>
<div class="d-flex flex-column user">
  <div class="mt-1">
    <span v-b-tooltip.click.top="user.id">{{ user.name }}</span>
    &nbsp;<span class="badge badge-pill badge-secondary" v-if="user.moderator">M</span>
  </div>
  <div>
    <b-img class="userpicbox" :src="user.picture0"/>
    <b-img class="userpicbox" :src="user.picture1"/>
  </div>
  <div>
    <button class="mr-2 badge btn-outline-info" v-if="$debug.isDeveloperOn && !user.test" @click="markTest(user.id, true)">mark test</button>
    <button class="mr-2 badge btn-outline-info" v-else-if="$debug.isDeveloperOn" @click="markTest(user.id, false)">unmark test</button>
    <button class="mr-2 badge btn-outline-secondary" v-if="!user.archived" @click="archiveUser(user.id, true)">Archive</button>
    <button class="mr-2 badge btn-outline-secondary" v-else @click="archiveUser(user.id, false)">Unarchive</button>
    <button class="mr-2 badge btn-outline-danger" v-if="$debug.isSuperAdminOn" @click="deleteUser(user.id)">Delete</button>
    <button v-b-tooltip.click.top="'Phone: ' + user.phonenumber + '\n Mail:' + user.email" class="mr-2 badge btn-outline-secondary" v-if="user.phonenumber || user.email">Contact Info</button>
  </div>
  <div>
    <div class="form-inline">
      <button v-b-tooltip.click.top="user.name + '\'s questions:\n' + formatQuestions(eventUser.questions)" class="mr-2 badge btn-outline-secondary" v-if="eventUser && eventUser.questions">Questions</button>
      <div class="row" v-if="roles">
        <label class="col" for="inline-form-custom-select-pref">Role:</label>
        <b-form-select class="col" inline size="sm" @change="onRoleChange" :value="eventUser ? (eventUser.role || null) : undefined" :options="roleOptions"/>
      </div>
    </div>
    <small v-if="eventUser">
      <span v-if="eventUser.gender || eventUser.interest">
        {{ eventUser.gender }} ↣ {{ eventUser.interest }}
      </span>
      {{ eventUser.status }}
      {{ eventUser.conversationCount }}{{ eventUser.maxConversations ? "/" + eventUser.maxConversations : "" }}
    </small>
    <small v-else>
      no eventUser
    </small>
  </div>
  <div>
    <div class="d-flex">
      <div style="display: inline;"><h2 style="display: inline;">Chat&nbsp;</h2></div>
      <div class="align-self-center">
        <button class="btn badge btn-outline-secondary" @click="chatArchive(user.id)">Archive</button>
      </div>
    </div>
    <chat
      ref="chats"
      :channel="user.id"
      author="moderator"
      :config="{isAdmin:true, autoScroll:true, limitOverflow:true}"
      />
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('admin-user');
import { db } from '@/services/db';
import { formatQuestions, objectFilter } from "@/services/utils";
import { chatArchive, chatDelete } from "@/services/chat.js";
import chat from "@/components/chat";

export default {
  props: {
    user: Object,
    event: Object,
    eventUser: Object,
    roles: Array,
  },
  components: {
    chat
  },
  data() {
    return {
      roleOptions: [],
    }
  },
  watch: {
    roles() {
      this.updateRoleOptions();
    }
  },
  mounted() {
    this.updateRoleOptions();
  },
  methods: {
    chatArchive,
    formatQuestions,
    markTest(uid, value) {
      db.collection("LiveUsers").doc(uid).update({test: value});
    },
    archiveUser(uid, value) {
      db.collection("LiveUsers").doc(uid).update({archived: value});
    },
    deleteUser(uid) {
      db.collection("LiveUsers").doc(uid).delete();
      log.log(`deleted user ${uid}`);
      chatDelete(uid);
    },
    updateRoleOptions() {
      log.log("updateRoleOptions");
      if (!this.roles)
        return;
      let options = this.roles.map(r => { return {value:r, text:r} });
      options.push({value:null, text:"none"});
      this.roleOptions = options;
    },
    onRoleChange(role) {
      log.log("onRoleChange=", role);
      let userRoles = this.event.userRoles || {};
      delete userRoles[this.user.id];
      if (role) {
        userRoles = objectFilter(userRoles, (v, k) => this.event.userIds.includes(k));
        userRoles[this.user.id] = role;
      }
      db.collection("LiveEvents").doc(this.event.id).update({userRoles});
      if (this.eventUser)
        db.collection(`LiveEvents/${this.event.id}/users`).doc(this.user.id).update({role});
    }
  }
}
</script>

<style scoped>
.user {
  min-width: 300px;
  margin-right: 20pt;
}

.userpicbox {
  height: 100px;
  width: 100px;
  overflow: hidden;
  border: solid 1px #000;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #000;
  object-fit: contain;
}

.dot.online {
  background-color:green;
}

.dot {
  height: 10px;
  width: 10px;
  background-color:lightgrey;
  border-radius: 50%;
  display: inline-block;
}
</style>