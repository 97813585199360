import { getLog } from "./log";
import { db } from "./db";
import { inheritedObj, createSafely } from "./dbutils";
import { mergeDeep } from "./utils";
let log = getLog('events');

export async function createEventFromTemplate(name, template, params) {
  log.log(`creating new event (${name}) from template: ${template}`);
  try {
    let data = await inheritedObj(db.collection("LiveEvents"), template, null, 'inherit');
    delete data.id;
    data.display = true;
    if (params)
      data = Object.assign(data, params);
    let doc = createSafely(db.collection("LiveEvents"), name, data);
    log.log(`created new event ${doc?.id}`);
    return doc;
  } catch (e) {
    log.error(`Failed to create event ${name}`, e);
  }
}

export async function resetEventFromTemplate(event) {
  let templateData = await inheritedObj(db.collection("LiveEvents"), event.template, null, 'inherit');
  let data = mergeDeep(event, templateData);
  log.log("resetEventFromTemplate merged result:", data);
  await db.collection("LiveEvents").doc(event.id).set(data);
  log.log(`reset event ${event.id}`, data);
}

export async function setupTestMatchMakingEvent(event) {
  log.log("will configure for unit test");
  let data = {
    callTimer: 0.5,
    firstCallTimer: 0.5,
  };
  await db.collection("LiveEvents").doc(event.id).set(data, {merge:true});
}
