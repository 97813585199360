<template>
<div>
  <div v-if="event">
    <h1>Event: {{ event.id }}</h1>
    <!-- Debug variables -->
    <debug-group v-if="$debug.isDeveloperOn">
        <debug-obj label="event" :objData="event" expand/>
        <debug-obj label="users" :objData="users" folded/>
        <debug-obj label="eventUsers" :objData="eventUsers" folded/>
    </debug-group>
    <div>
      <!-- Users and individual chats -->
      <div class="users">
        <div class="users scrollable-x">
          <div class="d-flex">
            <h1 style="display: inline;" class="mr-4">Users</h1>
            <div class="ml-3 align-self-center" v-if="$debug.isDeveloperOn">
              <a target="_blank" 
                :href="`/tests/multi-guests/${event.id}?users=` + listUsersBots">
                Open guest bots in MultiGuests page🔗
              </a>
              <a target="_blank" 
                :href="`/tests/multi-guests?eventId=${event.id}`">
                Open event in MultiGuests page🔗
              </a>   
            </div>
          </div>
          <div class="d-flex" v-if="users.length > 0">
            <div class="user" v-for="user in users" v-bind:key="user.id">
              <user-view class="mr-1"
                :user="user"
                :event="event"
                :eventUser="eventUsers[user.id]"
                :roles="event.workflowOptions.roles"
              />
              <div class="alert-info" v-if="$debug.isDeveloperOn">
                eventUser: {{ eventUsers[user.id] }}<br/>
                <button class="btn btn-primary badge" @click="resetEventUser(user.id)">Reset</button>
              </div>
              <div class="d-flex flex-column mt-1 mb-2">
                <button class="btn btn-warning m-1"
                  @click="/* confirm('Are you sure?') && */removeUserFromEvent(user.id, event)">
                  Remove from Event
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <p>No users joined has joined the event yet</p>
          </div>
        </div>
      </div>
      <!-- Event -->
      <div v-if="event">
        <AdminEvent
          v-bind:event="event" 
          v-bind:users="users"
          v-bind:eventUsers="eventUsers"
        />
      </div>
      <hr/>
      <!-- Rooms -->
      <div class="rooms">
        <AdminRooms  
          v-bind:users="users"
        />
      </div>
      <div class="mb-5">
      </div>
    </div>
  </div>
  <div class="mt-5" v-else>
    Loading...
  </div>
</div>
</template>

<script>
import { db } from '@/services/db';
import { getCollectionDocsFromIds } from "@/services/dbutils";
import { event as AdminEvent } from "./components/Event";
import AdminRooms from "./Rooms";
import debugObj from "@/components/debugObj";
import debugGroup from '@/components/debugGroup.vue';
import user from "./User";
import { removeUserFromEvent } from "@/services/user";
import { getLog } from "@/services/log";
import { bindMap, arraysEqual } from "@/services/utils";
import { initServerTime } from "@/services/server-time";
let log = getLog('admin');

export default {
  name: 'admin',
  components: {
    AdminEvent,
    AdminRooms,
    userView: user,
    debugObj,
    debugGroup,
  },
  data() {
    return {
      event: null,
      users: [],
      eventUsers: {},
      eventUsers_asArray: [],
    };
  },
  computed: {
    listUsersBots() {
      return JSON.stringify(this.users.filter(u => u.test).map((u) => { return {id: u.id, name: u.name}; }));
    }
  },
  watch: {
    $route() {
      this.loadEvent(this.$route.params.id);
    },
    // Note: workaround to support more than 10 events.
    // Disables proper update of users when changing property.
    async event(newEvent, oldEvent) {
      log.log("watch event");
      let newUserIds = newEvent ? (newEvent.userIds || []) : [];
      let oldUserIds = oldEvent ? (oldEvent.userIds || []) : [];
      if (!arraysEqual(newUserIds, oldUserIds)) {
        log.log("reloading users", newUserIds);
        this.users = await getCollectionDocsFromIds(db.collection("LiveUsers"), newUserIds);
      }
    }
  },
  mounted() {
    log.log("mounted()");
    initServerTime();
    this.loadEvent(this.$route.params.id);
  },
  methods: {
    removeUserFromEvent,
    confirm: window.confirm.bind(window),
    async loadEvent(eventId) {
      log.log("loading event", eventId);
      await this.$bind("event", db.collection("LiveEvents").doc(eventId));
      this.users = await getCollectionDocsFromIds(db.collection("LiveUsers"), this.event.userIds || []);
      bindMap(this, "eventUsers", db.collection(`LiveEvents/${this.event.id}/users`));
    },
    resetEventUser(userId) {
      db.collection(`LiveEvents/${this.event.id}/users`).doc(userId).delete();
    }
  }
};
</script>

<style scoped>

.scrollable-x {
  overflow: auto;
  overflow-x: scroll;
  width: calc(100vw - 200px);
}

</style>